import React from "react"
import { Nav } from "react-bootstrap"
import logo from "../images/afs_logotyp_standard.svg"

const Header = () => {
  return (
    <div className="container py-3 text-center">
      <a href="https://alternativforsverige.se">
        <img className="float-center mx-auto" src={logo} alt="" width="150" />
      </a>
    </div>
  )
}

export default Header
