import React from "react"

interface CostTickerState {
  TotalCost: number
  CostPerMilliSecond: number
  currentCost: number
  incrementInterval: number
}
interface CostTickerProps {
  incrementInterval: number
  totalCost: number
}

class CostTicker extends React.Component<CostTickerProps, CostTickerState> {
  constructor(props) {
    super(props)
    this.state = {
      TotalCost: props.totalCost,
      currentCost: 0,
      CostPerMilliSecond: props.totalCost / (365 * 24 * 60 * 60 * 1000),
      incrementInterval: props.incrementInterval,
    }
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({
        currentCost: Math.floor(
          this.getMilliSecondsPassed() * this.state.CostPerMilliSecond
        ),
      })
    }, this.state.incrementInterval)
  }

  getMilliSecondsPassed() {
    var current: Date = new Date()
    var previous: Date = new Date(current.getFullYear(), 0, 1)

    return Math.ceil(current.valueOf() - previous.valueOf())
  }

  render() {
    return (
      <span className="cost-header">
        {this.state.currentCost.toLocaleString("sv-SE", { currency: "SEK" })} kr
      </span>
    )
  }
}

export default CostTicker
