import React from "react"

const Footer = () => {
  return (
    <div className="container-fluid text-center py-5 bg-primary text-white">
      <div className="container py-5">
        <h1 className="mb-4">
          Tycker du också att pengarna borde stanna i Sverige?
        </h1>
        <p>Läs mer om Sveriges enda Swexit-parti!</p>
        <a
          href="https://alternativforsverige.se"
          className="btn btn-success text-white"
        >
          Klicka här!
        </a>
      </div>
      <div className="contain center-text">
        <small>
          Copyright: Alternativ för Sverige, {new Date().getFullYear()}
        </small>
      </div>
    </div>
  )
}

export default Footer
