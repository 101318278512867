import React from "react"
import Footer from "./Footer"
import Header from "./Header"
import Helmet from "react-helmet"
import socialMediaImage from "../images/EU-flag-red.jpg"

const Layout = ({ children }) => {
  const image = "https://vadkostar.eu" + socialMediaImage
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://vadkostar.eu" />

        <title>Vad kostar EU?</title>
        <meta name="title" content="Vad kostar EU?" />
        <meta
          name="description"
          content="Kolla hur mycket EU hittills kostat i år."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vadkostar.eu" />
        <meta property="og:title" content="Vad kostar EU?" />
        <meta
          property="og:description"
          content="Kolla hur mycket EU hittills kostat i år."
        />
        <meta property="og:image" content={image} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="4612" />
        <meta property="og:image:height" content="3075" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://vadkostar.eu" />
        <meta property="twitter:title" content="Vad kostar EU?" />
        <meta
          property="twitter:description"
          content="Kolla hur mycket EU hittills kostat i år."
        />
        <meta property="twitter:image" content={image}></meta>
      </Helmet>
      <Header></Header>

      {children}
      <Footer></Footer>
    </>
  )
}

export default Layout
